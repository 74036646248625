import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import shanazImg from "../../images/projectsfull/shanazfull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shanaz Razik interior architect</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="Shanaz Razik interior architect"
              projectfulldescription="For interior architect Shanaz Razik I designed a simple website to highlight her references and personal style."
              projectclient="Shanaz Razik"
              projectrole="Web Design"
              projectdate="2016"
              projectfullimg={<div className="project--img--inner"><img data-src={shanazImg} className="project--img lazyload" alt="Shanaz Razik interior architect" /></div>}
              projectnextlink="/projects/woonhaven"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;